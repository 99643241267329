<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col cols="12">
        <div class="title-text">
          Contact Information
        </div>
      </v-col>
      <v-col v-if="contact_info.phones.length" cols="12" sm="6" lg="4">
        <div
          class="d-flex justify-space-between justify-sm-start align-start pb-2 px-3 px-sm-0 pl-sm-4"
        >
          <div class="point-text pr-2">
            Phone:
          </div>
          <div :class="{ 'text-right': $vuetify.breakpoint.xsOnly }">
            <div v-if="!phones_expanded">
              <div class="point-text point-text--regular pull-text-below">
                {{ contact_info.phones[0].phone }}
                <v-icon
                  v-if="contact_info.phones[0].isPhoneSubscribed"
                  :title="contact_info.phones[0].isPhoneDefault ? 'Subscribed and Default for Phone Calls' : 'Subscribed for Phone Calls'"
                  :class="{'method-icon': true, 'default': contact_info.phones[0].isPhoneDefault}"
                >
                  mdi-phone
                </v-icon>
                <v-icon
                  v-if="contact_info.phones[0].isSmsSubscribed"
                  :title="contact_info.phones[0].isSmsDefault ? 'Subscribed and Default for SMS' : 'Subscribed for SMS'"
                  :class="{'method-icon': true, 'default': contact_info.phones[0].isSmsDefault}"
                >
                  mdi-message-text
                </v-icon>
                <v-icon
                  v-if="contact_info.phones[0].sourceExpirationDate"
                  :title="getExpirationTitle(contact_info.phones[0].sourceExpirationDate)"
                  :class="{'method-icon': true, 'expired-clock': isExpired(contact_info.phones[0].sourceExpirationDate)}"
                >
                  mdi-clock-outline
                </v-icon>
              </div>
              <span v-if="contact_info.phones[0].bestTime" class="point-text point-text--regular-small">
                Best Day:
              </span>
              <span v-if="contact_info.phones[0].bestTime" class="point-text point-text--regular-small-blue">
                {{ contact_info.phones[0].bestTime }}
              </span>
            </div>
            <div
              v-for="item in contact_info.phones"
              v-else
              :key="item.phone"
              class="mb-2"
            >
              <div class="point-text point-text--regular pull-text-below">
                {{ item.phone }}
                <v-icon
                  v-if="item.isPhoneSubscribed"
                  :title="item.isPhoneDefault ? 'Subscribed and Default for Phone Calls' : 'Subscribed for Phone Calls'"
                  :class="{'method-icon': true, 'default': item.isPhoneDefault}"
                >
                  mdi-phone
                </v-icon>
                <v-icon
                  v-if="item.isSmsSubscribed"
                  :title="item.isSmsDefault ? 'Subscribed and Default for SMS' : 'Subscribed for SMS'"
                  :class="{'method-icon': true, 'default': item.isSmsDefault}"
                >
                  mdi-message-text
                </v-icon>
                <v-icon
                  v-if="item.sourceExpirationDate"
                  :title="getExpirationTitle(item.sourceExpirationDate)"
                  :class="{'method-icon': true, 'expired-clock': isExpired(item.sourceExpirationDate)}"
                >
                  mdi-clock-outline
                </v-icon>
              </div>
            </div>
            <div
              v-if="$vuetify.breakpoint.smAndUp && !phones_expanded && contact_info.phones.length > 1"
              class="gray-text gray-text--small pt-3"
              @click="phones_expanded = true"
            >
              See {{ contact_info.phones.length - 1 }} more +
            </div>
          </div>
        </div>
        <div
          v-if="$vuetify.breakpoint.xsOnly && !phones_expanded && contact_info.phones.length > 1"
          class="d-flex justify-center"
        >
          <div
            class="gray-text gray-text--small pt-3"
            @click="phones_expanded = true"
          >
            See {{ contact_info.phones.length - 1 }} more +
          </div>
        </div>
      </v-col>
      <v-col v-if="contact_info.emails.length" cols="12" sm="6" lg="4">
        <div
          class="d-flex justify-space-between justify-sm-start align-start pb-2 px-3 px-sm-0"
        >
          <div class="point-text pr-2">
            Email:
          </div>
          <div :class="{ 'text-right': $vuetify.breakpoint.xsOnly }">
            <div v-if="!emails_expanded">
              <div class="point-text point-text--regular pull-text-below text-break">
                {{ contact_info.emails[0].emailAddress }}
                <v-icon
                  v-if="contact_info.emails[0].isSubscribed"
                  :title="contact_info.emails[0].isDefault ? 'Subscribed and Default' : 'Subscribed'"
                  :class="{'method-icon': true, 'default': contact_info.emails[0].isDefault}"
                >
                  mdi-email
                </v-icon>
                <v-icon
                  v-if="contact_info.emails[0].sourceExpirationDate"
                  :title="getExpirationTitle(contact_info.emails[0].sourceExpirationDate)"
                  :class="{'method-icon': true, 'expired-clock': isExpired(contact_info.emails[0].sourceExpirationDate)}"
                >
                  mdi-clock-outline
                </v-icon>
                <template
                  v-for="(component, key) in contactProfileEmailPluginIcons"
                >
                  <component
                    :is="component"
                    :key="'contact-info-email-icon-' + key"
                    :contact-email="contact_info.emails[0]"
                  />
                </template>
              </div>
              <span v-if="contact_info.emails[0].bestTime" class="point-text point-text--regular-small">
                Best Day:
              </span>
              <span v-if="contact_info.emails[0].bestTime" class="point-text point-text--regular-small-blue">
                {{ contact_info.emails[0].bestTime }}
              </span>
            </div>
            <div
              v-for="item in contact_info.emails"
              v-else
              :key="item.emailAddress"
              class="mb-2"
            >
              <div class="point-text point-text--regular pull-text-below">
                {{ item.emailAddress }}
                <v-icon
                  v-if="item.isSubscribed"
                  :title="item.isDefault ? 'Subscribed and Default' : 'Subscribed'"
                  :class="{'method-icon': true, 'default': item.isDefault}"
                >
                  mdi-email
                </v-icon>
                <v-icon
                  v-if="item.sourceExpirationDate"
                  :title="getExpirationTitle(item.sourceExpirationDate)"
                  :class="{'method-icon': true, 'expired-clock': isExpired(item.sourceExpirationDate)}"
                >
                  mdi-clock-outline
                </v-icon>
                <template
                  v-for="(component, key) in contactProfileEmailPluginIcons"
                >
                  <component
                    :is="component"
                    :key="'exp-contact-info-email-icon-' + key"
                    :contact-email="item"
                  />
                </template>
              </div>
              <span v-if="item.bestTime" class="point-text point-text--regular-small">
                Best Day:
              </span>
              <span v-if="item.bestTime" class="point-text point-text--regular-small-blue">
                {{ item.bestTime }}
              </span>
            </div>
            <div
              v-if="$vuetify.breakpoint.smAndUp && !emails_expanded && contact_info.emails.length > 1"
              class="gray-text gray-text--small pt-3"
              @click="emails_expanded = true"
            >
              See {{ contact_info.emails.length - 1 }} more +
            </div>
          </div>
        </div>
        <div
          v-if="$vuetify.breakpoint.xsOnly && !emails_expanded && contact_info.emails.length > 1"
          class="d-flex justify-center"
        >
          <div
            class="gray-text gray-text--small pt-3"
            @click="emails_expanded = true"
          >
            See {{ contact_info.emails.length - 1 }} more +
          </div>
        </div>
      </v-col>
      <v-col v-if="contact_info.locations.length" cols="12" sm="6" lg="4">
        <div
          class="d-flex justify-space-between justify-sm-start align-start pb-2 px-3 px-sm-0 pl-sm-4"
        >
          <div class="point-text pr-2">
            Location:
          </div>
          <div :class="{ 'text-right': $vuetify.breakpoint.xsOnly }">
            <div v-if="!locations_expanded && contact_info.locations[0]">
              <div
                v-if="contact_info.locations[0].addressLine1"
                class="point-text point-text--regular pull-text-below"
              >
                {{ contact_info.locations[0].addressLine1 }} {{ contact_info.locations[0].addressLine2 }}
                <v-icon
                  v-if="contact_info.locations[0].isDefault"
                  title="Default"
                  class="method-icon default"
                >
                  mdi-star
                </v-icon>
              </div>
              
              <span class="point-text point-text--regular pull-text-below">
                {{ contact_info.locations[0].city }}, {{ contact_info.locations[0].state }} {{ contact_info.locations[0].postalCode }} {{ contact_info.locations[0].country }}
                <v-icon
                  v-if="!contact_info.locations[0].addressLine1 && contact_info.locations[0].isDefault"
                  title="Default"
                  class="method-icon default"
                >
                  mdi-star
                </v-icon>
                <v-icon
                  v-if="contact_info.locations[0].sourceExpirationDate"
                  :title="getExpirationTitle(contact_info.locations[0].sourceExpirationDate)"
                  :class="{'method-icon': true, 'expired-clock': isExpired(contact_info.locations[0].sourceExpirationDate)}"
                >
                  mdi-clock-outline
                </v-icon>
              </span>
            </div>
            <div
              v-for="item in contact_info.locations"
              v-else
              :key="item.emailAddress"
              class="mb-2"
            >
              <div
                v-if="item.addressLine1"
                class="point-text point-text--regular pull-text-below"
              >
                {{ item.addressLine1 }} {{ item.addressLine2 }}
                <v-icon
                  v-if="item.isDefault"
                  title="Default"
                  class="method-icon default"
                >
                  mdi-star
                </v-icon>
              </div>

              <span class="point-text point-text--regular pull-text-below">
                {{ item.city }}, {{ item.state }} {{ item.postalCode }} {{ item.country }}
                <v-icon
                  v-if="!item.addressLine1 && item.isDefault"
                  title="Default"
                  class="method-icon default"
                >
                  mdi-star
                </v-icon>
                <v-icon
                  v-if="item.sourceExpirationDate"
                  :title="getExpirationTitle(item.sourceExpirationDate)"
                  :class="{'method-icon': true, 'expired-clock': isExpired(item.sourceExpirationDate)}"
                >
                  mdi-clock-outline
                </v-icon>
              </span>
            </div>
            <div
              v-if="$vuetify.breakpoint.smAndUp && !locations_expanded && contact_info.locations.length > 1"
              class="gray-text gray-text--small pt-3"
              @click="locations_expanded = true"
            >
              See {{ contact_info.locations.length - 1 }} more +
            </div>
          </div>
        </div>
        <div
          v-if="$vuetify.breakpoint.xsOnly && !locations_expanded && contact_info.locations.length > 1"
          class="d-flex justify-center"
        >
          <div
            class="gray-text gray-text--small pt-3"
            @click="locations_expanded = true"
          >
            See {{ contact_info.locations.length - 1 }} more +
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "ContactInfo",
  mixins: [datesMixin],
  props: {
    contact_info: {
      default: () => {},
    },
  },
  data() {
    return {
      phones_expanded: false,
      emails_expanded: false,
      locations_expanded: false
    };
  },
  computed: {
    contactProfileEmailPluginIcons() {
      return this.$store.getters['user/pluginComponents']('contactInfoEmailIcons');
    }
  },
  methods: {
    isExpired(expirationDate) {
      const d = new Date(expirationDate.replace("00:00:00", "12:00:00"));
      d.setHours(0, 0, 0, 0);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return d <= today;
    },
    getExpirationTitle(expirationDate) {
      if (this.isExpired(expirationDate)) {
        return 'Expired on ' + this.getDateUTC(expirationDate);
      }

      return 'Expires on ' + this.getDateUTC(expirationDate);
    },
  }
};
</script>

<style lang="scss">
.v-icon.method-icon {
  font-size: 20px;
  color: #2b84eb;

  &.default {
    color: rgb(228, 166, 26);
  }

  &.expired-clock {
    color: #9d0f0f;
  }
}
</style>
